import React from 'react'
import Layout from '../components/layout'
import { MainContainer} from '../styles/common.styled'
import { Section, Heading, Text, CoverImage } from '../styles/default-template.styled'
import PortableText from '../components/BlockContent/PortableText'
import { graphql } from 'gatsby' 
import SEO from '../components/seo'


export const query = graphql`
    query getWorkField($slug: String!) {
        sanityWorkField(slug: {current: {eq: $slug}}) {
            title
            image {
                asset {
                    fluid(maxWidth: 1200, maxHeight: 380) {
                        ...GatsbySanityImageFluid
                    }
                }
            }
            _rawBody
            seo {
                description,
                image {
                    asset {
                        url
                    }
                }
            }

        }
    }
`

const WorkFieldTemplate = ({data}) => {
    const {title, image, _rawBody, seo} = data.sanityWorkField
    return (
        <Layout>
            <SEO title={title} seo={seo} />
            <Section>
                <MainContainer>
                    <Heading>{title}</Heading>
                    <Text>
                        <PortableText blocks={_rawBody} />
                    </Text>
                </MainContainer>
                <CoverImage fluid={image.asset.fluid} />
            </Section>
        </Layout>
    )
}

export default WorkFieldTemplate